<template>
  <b-overlay :show="show" rounded="sm" spinner-small spinner-variant="primary">
    <template #overlay>
      <div class="text-center">
        <b-spinner v-show="show" type="grow" label="Loading..." />
        <h4 id="cancel-label">
          {{ $t("loader") }}
        </h4>
      </div>
    </template>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Reset Password v1 -->
        <b-card class="mb-0">
          <!-- logo -->
          <b-link class="brand-logo">
            <a href="/">
              <img
                class="logo"
                src="../../../assets/images/logo/logo-nft-dark.png"
                style="width: 188px"
                alt="brand-logo"
              />
            </a>
          </b-link>

          <b-card-title class="mb-1"> {{ $t("resetPasswordPage.header") }} 🔒 </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("resetPasswordPage.body") }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              method="POST"
              class="auth-reset-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <!-- password -->
              <b-form-group
                :label="$t('resetPasswordPage.password')"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                :label="$t('resetPasswordPage.confirmPassword')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-alert v-if="msg" show variant="warning" class="warning" style="color: red">
                {{ msg }}
              </b-alert>

              <!-- submit button -->
              <b-button block type="submit" variant="primary">
                {{ $t("resetPasswordPage.sendResetLink") }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t("resetPasswordPage.backToLogin") }}
            </b-link>
          </p>
        </b-card>
        <!-- /Reset Password v1 -->
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BCardTitle,
  BCardText,
  BAlert,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BSpinner,
  BOverlay,
} from "bootstrap-vue";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions } from "vuex";

export default {
  components: {
    VuexyLogo,
    BSpinner,
    BOverlay,
    BCard,
    BButton,
    BAlert,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      cPassword: "",
      password: "",
      show: false,
      required,
      msg: null,

      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  created() {
    if (localStorage.getItem("language") === "ar") {
      document.documentElement.setAttribute("dir", "rtl");
      document.body.style.fontFamily = "Tajawal";
    } else {
      document.documentElement.setAttribute("dir", "ltr");
      // document.body.style.fontFamily = 'Montserrat,Helvetica,Arial, serif'
      document.body.style.fontFamily = "nftbplus,sans-serif";
    }
  },

  methods: {
    ...mapActions({
      resetPassword: "auth/resetPasswordToken",
    }),
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === "password" ? "text" : "password";
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;
          const formData = new FormData();
          formData.append("password", this.password);
          formData.append("token", this.$route.params.token ? this.$route.params.token : null);
          this.resetPassword(formData)
            .then((response) => {
              this.show = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.show = false;
              this.msg = error.response.data.message;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
